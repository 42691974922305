export enum OrderTag {
  Transit = 'transit',
  ReleaseProcess = 'releaseProcess',
  WaitingForPayment = 'waitingForPayment',
  OnTheWay = 'onTheWay',
  Delivered = 'delivered',
}

export interface Order {
  doctor: {
    id: string;
    name: string;
  };
  orderState: {
    name: string;
    selectable: boolean;
    tag: OrderTag;
  };
  pacient: {
    id: string;
    name: string;
  };
  product: {
    name: string;
  };
  sapOrder: string;
  shipmentNumber: string;
  id: number;
  etaDate: string;
}

export interface OrderCardProps {
  order: Order;
}
