import styled from 'styled-components';

const Button = styled.button`
  background: ${({ theme }) => theme.main.black};
  border-radius: 5px;
  color: ${({ theme }) => theme.main.background};
  padding: 1rem;
  text-transform: uppercase;
  transition: all 300ms ease-out;
  width: 100%;
  &:disabled {
    opacity: 0.5;
  }
`;

export default Button;
