import api from '../config/api';

export interface LoginData {
  email: string;
  password: string;
}

interface LoginResponse {
  token: string;
}

const AuthServices = {
  login: (data: LoginData) => api.post<LoginResponse>('/doctor/login', data),
  postChangePassword: (newPassword: string, hash: string) =>
    api.post('/user/change-password', { newPassword, hash }),
  postRecoverPassword: (email: string) =>
    api.post('/user/recover/doctor', { email }),
};

export default AuthServices;
