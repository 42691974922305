import { FC, lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import '../config/api';
import Theme from '../config/theme';
import Login from './pages/Login/Login';
import routes from '../constants/routes';

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: 16px;
  }
  body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
  }

  #app {
    height: 100%;
    width: 100%;
  }
`;

const LazyDashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const LazyChangePassword = lazy(
  () => import('./pages/ChangePassword/ChangePassword'),
);
const LazyRecoverPassword = lazy(
  () => import('./pages/RecoverPassword/RecoverPassword'),
);

const App: FC = () => (
  <Theme>
    <GlobalStyles />
    <Router>
      <Switch>
        <Route exact path={routes.LOGIN}>
          <Login />
        </Route>
        <Route exact path={routes.DASHBOARD}>
          <Suspense fallback={<h1>Loading...</h1>}>
            <LazyDashboard />
          </Suspense>
        </Route>
        <Route exact path={routes.RECOVER_PASSWORD}>
          <Suspense fallback={<h1>Loading...</h1>}>
            <LazyRecoverPassword />
          </Suspense>
        </Route>
        <Route exact path={routes.CHANGE_PASSWORD}>
          <Suspense fallback={<h1>Loading...</h1>}>
            <LazyChangePassword />
          </Suspense>
        </Route>
        <Route exact path="/">
          <Redirect to={routes.DASHBOARD} />
        </Route>
        <Route>
          <Redirect to={routes.DASHBOARD} />
        </Route>
      </Switch>
    </Router>
  </Theme>
);

export default App;
