import { useState } from 'react';
import i18next, { TFunction } from 'i18next';

const debug = process.env.NODE_ENV === 'development';

i18next.init({
  lng: 'es',
  debug,
  initImmediate: false,
  resources: {
    es: {
      translation: {
        cancel: 'Cancelar',
        login: 'Iniciar sesión',
        logout: 'Cerrar sesión',
        save: 'Guardar',
        newPassword: 'Nueva contraseña',
        repeatPassword: 'Repetir contraseña',
        email: 'Email',
        sendEmail: 'Enviar correo',
      },
      login: {
        forgetPassword: 'Olvide mi contraseña',
      },
      dashboard: {
        title: 'Mis órdenes',
        searchInputLabel: 'Buscar paciente',
      },
      orderCard: {
        etaDate: 'Llega el {{date}}',
      },
      errors: {
        invalidLogin: 'Usuario o contraseña inválida',
      },
      changePassword: {
        success: 'Contraseña cambiada con éxito',
      },
      recoverPassword: {
        success: 'Email enviado con éxito',
      },
    },
  },
});

export const useI18next = (): [TFunction, (lng: string) => void] => {
  const t = (text: string, interpolation: any) =>
    i18next.t(text, interpolation);
  const [state, setState] = useState({ t });
  const changeLanguage = (lng: string) => {
    i18next.changeLanguage(lng, (err, newT: TFunction) => {
      setState({ t: newT });
    });
  };
  return [state.t, changeLanguage];
};
