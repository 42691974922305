import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormValues, LoginProps } from './types';
import hecaLogo from '../../assets/heca-logo.svg';
import alignLogo from '../../assets/align-logo.svg';
import {
  ErrorMessage,
  LinkContainer,
  LoginContainer,
  LoginFooter,
  LoginFormContainer,
} from './styles';
import TextInput from '../../components/TextInput/TextInput';
import Button from '../../components/Button/Button';
import routes from '../../../constants/routes';
import AuthServices from '../../../services/auth.services';
import { useI18next } from '../../../config/i18next';
import api from '../../../config/api';
import { emailRegex } from '../../../constants/regex';

const Login: FC<LoginProps> = ({ onSubmit }) => {
  const [t] = useI18next();
  const [error, setError] = useState(false);
  const { register, control, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
  });
  const { isValid, isSubmitting } = useFormState({ control });
  const history = useHistory();
  const formSubmit: SubmitHandler<FormValues> = async (values) => {
    setError(false);
    try {
      const { data } = await AuthServices.login(values);
      localStorage.setItem('token', data.token);
      api.defaults.headers = { Authorization: `Bearer ${data.token}` };
      history.push(routes.DASHBOARD);
    } catch (e) {
      setError(true);
    }
  };
  return (
    <LoginContainer>
      <img className="logo" src={hecaLogo} alt="HECA_LOGO" />
      <LoginFormContainer>
        <form onSubmit={handleSubmit(onSubmit || formSubmit)}>
          <TextInput
            id="email"
            labelText="Usuario"
            placeholder="Usuario"
            {...register('email', {
              required: true,
              pattern: emailRegex,
            })}
          />
          <TextInput
            id="password"
            placeholder="Contraseña"
            labelText="Contraseña"
            type="password"
            {...register('password', { required: true })}
          />
          <LinkContainer>
            <Link to={routes.RECOVER_PASSWORD}>
              {t('login:forgetPassword')}
            </Link>
          </LinkContainer>
          <div>
            <Button type="submit" disabled={!isValid || isSubmitting}>
              {t('login')}
            </Button>
          </div>
        </form>
      </LoginFormContainer>
      <ErrorMessage show={error} role="alert">
        {t('errors:invalidLogin')}
      </ErrorMessage>
      <LoginFooter>
        <img src={alignLogo} alt="ALIGN_LOGO" />
      </LoginFooter>
    </LoginContainer>
  );
};

export default Login;
