import styled from 'styled-components';

export const TextInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.875rem 0;
  width: 100%;
  label {
    margin-bottom: 0.5rem;
  }
  input {
    border: 1px solid ${({ theme }) => theme.main.inputBorder};
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
    margin: 0;
    outline: none;
    padding: 1.125rem 1.875rem;
    width: 100%;
  }
`;
