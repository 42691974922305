import styled from 'styled-components';

export const LoginContainer = styled.main`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 3.125rem;
  width: 100%;
  .logo {
    margin-top: 70px;
  }
`;

export const LoginFormContainer = styled.div`
  width: 100%;
`;

export const LoginFooter = styled.footer`
  box-sizing: border-box;
  margin-top: auto;
  padding: 1rem 0;
`;

export const ErrorMessage = styled.p<{ show: boolean }>`
  color: #ff0000;
  display: ${({ show }) => (show ? 'block' : 'none')};
  font-size: 0.875rem;
  margin-top: 3rem;
  text-transform: uppercase;
`;

export const LinkContainer = styled.div`
  margin-bottom: 2rem;
  text-align: center;
  a {
    color: inherit;
    font-size: 1rem;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
  }
`;
