import { FC, forwardRef } from 'react';
import { TextInputProps } from './types';
import { TextInputContainer } from './styles';

// We use forward ref for the react hook form can access it
const TextInput: FC<TextInputProps> = forwardRef(
  ({ id, labelText, ...rest }, ref: any) => (
    <TextInputContainer>
      <label htmlFor={id}>{labelText}</label>
      <input ref={ref} id={id} {...rest} />
    </TextInputContainer>
  ),
);

TextInput.defaultProps = {
  type: 'text',
};

export default TextInput;
