import { OrderTag } from '../app/pages/Dashboard/components/OrderCard/types';

export enum Themes {
  Default = 'default',
  Dark = 'dark',
}

interface ThemeProperties {
  background: string;
  inputBorder: string;
  black: string;
  lightBlack: string;
  grey: string;
  [OrderTag.OnTheWay]: string;
  [OrderTag.Delivered]: string;
  [OrderTag.ReleaseProcess]: string;
  [OrderTag.WaitingForPayment]: string;
  [OrderTag.Transit]: string;
}

const themes: { [key in Themes]: ThemeProperties } = {
  [Themes.Default]: {
    background: '#ffffff',
    inputBorder: '#dadada',
    black: '#070606',
    lightBlack: 'rgba(0, 0, 0, 0.85)',
    grey: '#E5E5E5',
    [OrderTag.OnTheWay]: '#009ACE',
    [OrderTag.Delivered]: '#070606',
    [OrderTag.ReleaseProcess]: '#FF8D07',
    [OrderTag.WaitingForPayment]: '#10C700',
    [OrderTag.Transit]: '#009ACE',
  },
  [Themes.Dark]: {
    background: 'rgb(60,41,41)',
    inputBorder: '#dadada',
    black: '#070606',
    lightBlack: 'rgba(0, 0, 0, 0.85)',
    grey: '#E5E5E5',
    [OrderTag.OnTheWay]: '#009ACE',
    [OrderTag.Delivered]: '#070606',
    [OrderTag.ReleaseProcess]: '#FF8D07',
    [OrderTag.WaitingForPayment]: '#10C700',
    [OrderTag.Transit]: '#009ACE',
  },
};

export default themes;
